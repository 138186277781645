<template>
  <div class="viewer-page">
    <div class="viewer-header">
      <div class="breadcrumbs">
        <router-link :to="prevRoute">{{ prevRouteLabel }}</router-link> <span>&rsaquo; Viewer</span>
      </div>
      <div class="viewer-controls" v-if="loaded">
        <div class="back">
          <router-link :to="prevRoute">
            <button class="viewer-control-button input-style">
              <span class="big">&lsaquo;</span><span> Back</span>
            </button>
          </router-link>
        </div>
        <icon-input
          class="search-control search-bar"
          :value="searchValue"
          :ref="searchBarRef"
          type="search"
          placeholder="Search this document..."
          @blur="onSearch"
          @keyup.enter="blurSearchInput"
        >
          <search-icon class="search-icon" :size="26" />
        </icon-input>
        <button class="viewer-control-button input-style search-button" @click="mobileSearchButtonClick">
          <search-icon class="search-icon" :size="26" />
          <span class="search-button-result-count">{{ searchTotalCount || '' }}</span>
        </button>
        <button
          class="viewer-control-button input-style ocr-button"
          :class="{'is-active': isDescriptionPanelActive}"
          v-if="showDescriptionControl"
          @click="toggleDescriptionPanel"
        >
          <image-text-icon :size="26"></image-text-icon>
        </button>
        <button
          class="viewer-control-button input-style ocr-button"
          :class="{'is-active': isOcrPanelActive}"
          v-if="showOcrButton"
          @click="toggleOcrPanel"
        >
          <text-recognition-icon :size="24"></text-recognition-icon>
        </button>
        <select
          class="viewer-control-button input-style ocr-button"
          v-if="userIsStaffState && currentPageOcrData.ocr_type"
          @change="selectChange"
        >
          <option
            v-for="ocr_type in currentPageOcrData.ocr_type_available"
            :value="ocr_type"
            :selected="ocr_type === currentPageOcrData.ocr_type"
          >
            {{ ocr_type }}
          </option>
        </select>
        <button
          class="viewer-control-button input-style details-button"
          :class="{'is-active': isDetailsPanelActive}"
          @click="toggleDetails"
        >
          <details-icon class="details-icon" :size="26" />
        </button>
        <button
          class="viewer-control-button input-style details-button"
          :class="{'is-active': isThumbnailNavPanelActive}"
          @click="toggleThumbnailNav"
        >
          <thumbnails-icon class="details-icon" :size="26" />
        </button>
        <button class="viewer-control-button input-style rotate-button" @click="rotateRight">
          <rotate-right-icon class="details-icon" :size="26" />
        </button>
        <div class="pages-navigation-container">
          <input
            v-if="showPageInput"
            :ref="pageInputRef"
            class="page-input"
            type="text"
            placeholder="Jump to page..."
            @blur="jumpToPage"
            @keyup.enter="blurPageInput"
          />
          <button class="viewer-control-button input-style page-nav" v-else @click="togglePageInput">
            {{ pagesInfo }}
          </button>
          <button
            class="viewer-control-button input-style page-turn nav-prev"
            title="Previous Page [PAGE UP]"
            v-if="hasPrevPage"
            @click="goToPrevPage"
          >
            <chevron-left :size="24" />
          </button>
          <button
            class="viewer-control-button input-style page-turn nav-next"
            title="Next Page [PAGE DOWN]"
            v-if="hasNextPage"
            @click="goToNextPage"
          >
            <chevron-right :size="24" />
          </button>
        </div>
      </div>
    </div>

    <mcr-loading-indicator :loading="loading"></mcr-loading-indicator>
    <viewer-container
      v-if="loaded"
      :ref="viewerRef"
      :page="currentPage"
      :pageOcr="currentPageOcrData"
      :loadingPage="loadingPage"
      :loadingOcr="loadingOcr"
      :showNoImages="pagesTotalCount === 0"
      :userHasFullAccess="userHasFullAccess"
      :searchValue="searchValue"
      :init-show-dict="initShowDict"
      :rotate="rotateDegrees"
      @change-show-ocr-panel="onChangeShowOcrPanel"
      @change-show-description-panel="onChangeShowDescriptionPanel"
      @change-show-sidebar-details="onChangeShowSidebarDetails"
      @change-show-sidebar-navigation="onChangeShowSidebarNavigation"
    >
      <slot name="viewer-top" slot="viewport-top"></slot>
      <slot
        name="sidebar-details"
        slot="sidebar-details"
        slot-scope="{setShowSidebar}"
        :setShowSidebar="setShowSidebar"
      ></slot>
      <thumbnail-navigation-vertical
        :pages-count="pagesTotalCount"
        :active-priority="currentPageNumber"
        :get-thumbnail-src="getThumbnailSrc"
        @select="onThumbnailSelect"
        slot="sidebar-thumbnails"
      ></thumbnail-navigation-vertical>
      <slot
        name="sidebar-search-results"
        slot="sidebar-search-results"
        slot-scope="{setShowSidebar, setNormalSidebarWidth}"
        :setShowSidebar="setShowSidebar"
        :setNormalSidebarWidth="setNormalSidebarWidth"
      ></slot>
      <slot name="preview-unavailable" slot="preview-unavailable" />
      <slot name="description-panel" slot="description-panel"></slot>
    </viewer-container>
    <touch-page-navigation
      :has-next="hasNextPage"
      :has-previous="hasPrevPage"
      @previous="goToPrevPage"
      @next="goToNextPage"
    />
    <slot name="modals"></slot>
  </div>
</template>

<script>
import IconInput from '@common/elements/inputs/IconInput';
import {descriptionChoices} from '@common/elements/layouts/book-viewer/constants';
import ChevronLeft from 'vue-material-design-icons/ChevronLeft';
import ChevronRight from 'vue-material-design-icons/ChevronRight';
import ThumbnailsIcon from 'vue-material-design-icons/ImageMultiple';
import ImageTextIcon from 'vue-material-design-icons/ImageText';
import DetailsIcon from 'vue-material-design-icons/InformationOutline';
import SearchIcon from 'vue-material-design-icons/Magnify';
import RotateRightIcon from 'vue-material-design-icons/RotateRight';
import TextRecognitionIcon from 'vue-material-design-icons/TextRecognition';
import {mapGetters} from 'vuex';

import ThumbnailNavigationVertical from './ThumbnailNavigationVertical';
import TouchPageNavigation from './TouchPageNavigation';
import ViewerContainer from './ViewerContainer';

export default {
  props: {
    prevRoute: Object,
    prevRouteLabel: String,
    currentPage: Object,
    currentPageOcrData: Object,
    loading: Boolean,
    loadingPage: Boolean,
    loadingOcr: Boolean,
    loaded: Boolean,
    pagesTotalCount: Number,
    searchTotalCount: Number,
    searchValue: String,
    userHasFullAccess: Boolean,
    currentPageNumber: Number,
    description: String,
    showDescriptionControl: {type: Boolean, default: false},
    showOcrButton: {type: Boolean, default: false},
    initShowDict: {type: Boolean, default: false},
    getThumbnailSrc: {type: Function},
  },
  data() {
    return {
      rotateDegrees: 0,
      showPageInput: false,
      viewerRef: 'viewer',
      searchBarRef: 'search-bar',
      pageInputRef: 'page-input',
      isDescriptionPanelActive: false,
      isOcrPanelActive: false,
      isDetailsPanelActive: false,
      isThumbnailNavPanelActive: false,
    };
  },
  created() {
    window.addEventListener('keyup', this.keyupHandler);
  },
  destroyed() {
    window.removeEventListener('keyup', this.keyupHandler);
  },
  computed: {
    ...mapGetters(['userIsStaffState']),
    pagesInfo() {
      return `Page ${this.currentPageNumber} / ${this.pagesTotalCount}`;
    },
    hasPrevPage() {
      return this.currentPageNumber > 1;
    },
    hasNextPage() {
      return this.currentPageNumber < this.pagesTotalCount;
    },
  },
  methods: {
    onSearch() {
      const value = this.$refs[this.searchBarRef].$refs.input.value.trim();
      if (this.searchValue === value) {
        return;
      }
      this.$emit('search', value);
      const hasValue = Boolean(value);
      this.$refs[this.viewerRef].setShowSidebarSearchResults(hasValue);
      this.$refs[this.viewerRef].setShowSidebarDetails(false);
      this.$refs[this.viewerRef].setShowSidebarNavigation(false);
    },
    keyupHandler(event) {
      const isInput = ['INPUT', 'TEXTAREA'].includes(event.target.tagName);
      if (isInput) {
        return;
      }
      if (event.keyCode == 34 && this.hasNextPage) {
        // page down
        return this.goToNextPage();
      }
      if (event.keyCode == 33 && this.hasPrevPage) {
        // page up
        return this.goToPrevPage();
      }

      const viewer = this.$refs[this.viewerRef];
      const imageWithMatches = viewer ? viewer.$refs[viewer.imageWithMatchesRef] : null;
      const panzoom = imageWithMatches ? imageWithMatches.$refs.panzoom : null;
      if (!panzoom) {
        return;
      }

      if (event.keyCode === 40) {
        // down
        return panzoom.$panZoomInstance.moveBy(0, -this.getOffsetForButtonPan(panzoom), true);
      }
      if (event.keyCode === 38) {
        // up
        return panzoom.$panZoomInstance.moveBy(0, this.getOffsetForButtonPan(panzoom), true);
      }
      if (event.keyCode === 39) {
        // right
        return panzoom.$panZoomInstance.moveBy(-this.getOffsetForButtonPan(panzoom), 0, true);
      }
      if (event.keyCode === 37) {
        // left
        return panzoom.$panZoomInstance.moveBy(this.getOffsetForButtonPan(panzoom), 0, true);
      }
      if (event.keyCode === 189 || event.keyCode === 109) {
        // DASH or SUBTRACT
        const offset = this.getOffsetForButtonZoom(panzoom);
        return panzoom.$panZoomInstance.zoomTo(offset.x, offset.y, 0.8);
      }
      if (event.keyCode === 187 || event.keyCode === 107) {
        // EQUAL SIGN or ADD
        const offset = this.getOffsetForButtonZoom(panzoom);
        return panzoom.$panZoomInstance.zoomTo(offset.x, offset.y, 1.2);
      }
    },
    getOffsetForButtonPan(panzoom) {
      const clientRect = panzoom.$el.getBoundingClientRect();
      const offset = Math.min(clientRect.width, clientRect.height);
      return offset * 0.2;
    },
    getOffsetForButtonZoom(panzoom) {
      const transformOrigin = panzoom.$panZoomInstance.getTransformOrigin();
      const ownerRect = panzoom.$el.getBoundingClientRect();
      return transformOrigin
        ? {
            x: ownerRect.width * transformOrigin.x,
            y: ownerRect.height * transformOrigin.y,
          }
        : {
            x: ownerRect.width / 2,
            y: ownerRect.height / 2,
          };
    },
    selectChange(event) {
      this.$emit('select-ocr-type', event.target.value);
    },
    blurSearchInput() {
      this.$refs[this.searchBarRef].$refs.input.blur();
    },
    toggleOcrPanel() {
      this.$refs[this.viewerRef].toggleDescriptionPanel(descriptionChoices.OCR);
    },
    toggleDescriptionPanel() {
      this.$refs[this.viewerRef].toggleDescriptionPanel(descriptionChoices.CUSTOM);
    },
    toggleDetails() {
      this.$refs[this.viewerRef].toggleShowSidebarDetails();
    },
    toggleThumbnailNav() {
      this.$refs[this.viewerRef].toggleSidebarNavigation();
    },
    mobileSearchButtonClick() {
      this.$refs[this.viewerRef].setShowSidebarSearchResults(true);
    },
    blurPageInput() {
      this.$refs[this.pageInputRef].blur();
    },
    jumpToPage() {
      const pageNumber = parseInt(this.$refs[this.pageInputRef].value);
      if (!isNaN(pageNumber) && pageNumber > 0 && pageNumber <= this.pagesTotalCount) {
        this.goToPage(pageNumber, 'jump');
      }
      this.togglePageInput();
    },
    togglePageInput() {
      this.showPageInput = !this.showPageInput;
      this.$nextTick(() => {
        // for input to be already showed, when we focus it.
        if (this.showPageInput) {
          this.$refs[this.pageInputRef].focus();
        }
      });
    },
    goToPrevPage() {
      this.goToPage(this.currentPageNumber - 1, 'previous');
    },
    goToNextPage() {
      this.goToPage(this.currentPageNumber + 1, 'next');
    },
    onThumbnailSelect(thumbnail) {
      this.goToPage(thumbnail.priority, 'thumbnail');
      if (this.$refs[this.viewerRef].sidebarFullViewportWidth) {
        this.$refs[this.viewerRef].toggleSidebarNavigation();
      }
    },
    goToPage(pageNumber, pageNavigateName) {
      this.$emit('change-page', {pageNumber, pageNavigateName});
    },
    resetRotate() {
      this.rotateDegrees = 0;
    },
    rotateRight() {
      this.rotateDegrees = this.rotateDegrees >= 270 ? 0 : this.rotateDegrees + 90;
    },
    onChangeShowOcrPanel(shown) {
      this.isOcrPanelActive = shown;
    },
    onChangeShowDescriptionPanel(shown) {
      this.isDescriptionPanelActive = shown;
    },
    onChangeShowSidebarDetails(shown) {
      this.isDetailsPanelActive = shown;
    },
    onChangeShowSidebarNavigation(shown) {
      this.isThumbnailNavPanelActive = shown;
    },
  },
  name: 'ViewerPage',
  components: {
    ThumbnailsIcon,
    IconInput,
    SearchIcon,
    RotateRightIcon,
    TextRecognitionIcon,
    ChevronLeft,
    ChevronRight,
    TouchPageNavigation,
    ViewerContainer,
    DetailsIcon,
    ImageTextIcon,
    ThumbnailNavigationVertical,
  },
};
</script>
<style lang="scss" scoped>
.viewer-page {
  display: flex;
  flex-direction: column;
  .viewer-header {
    padding: 0px 24px;
    padding-top: 16px;
    padding-bottom: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-shadow: $box-shadow-light;
    z-index: 2;
    border-bottom: 1px solid $divider-line-color;
    justify-content: space-between;
    flex-wrap: wrap;

    .breadcrumbs,
    .back {
      flex-grow: 1;
      margin-bottom: 12px;
    }
    .back button {
      height: 44px;
    }
    .breadcrumbs {
      span {
        color: $supplemental-text-color;
        font-size: 1.18em;
        opacity: 0.8;
        margin: 0 3px;
      }
    }
    .viewer-controls {
      .viewer-control-button {
        background-color: #fff;
        background-image: linear-gradient(0deg, rgba(black, 0.05), rgba(black, 0));
        color: #555;
        white-space: nowrap;
        &::v-deep {
          .material-design-icon {
            display: flex;
          }
        }
        &:hover,
        &:focus {
          text-shadow: none;
          background-color: #fff;
          box-shadow: $box-shadow;
        }

        &.is-active {
          color: $power-red;
        }
        @media only screen and (max-width: 480px) {
          padding: 9px 11px;
        }
      }
    }
    .viewer-controls::v-deep {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;

      > * {
        margin-bottom: 12px;
        &:not(:first-child) {
          margin-left: 8px;
        }
      }

      .search-button {
        display: none;
        margin-left: 0px;
        .search-icon {
          display: flex;
        }
        .search-button-result-count {
          font-size: 0.9em;
          margin-left: 1px;
        }
      }

      .back {
        display: none;
        margin-right: 8px;
        a {
          white-space: nowrap;
          span.big {
            font-size: 1.3em;
            line-height: 0;
          }
        }
      }
    }
    @media only screen and (max-width: $breakpoint-tablet) {
      padding-left: 16px;
      padding-right: 16px;
      .breadcrumbs {
        display: none;
      }
      .viewer-controls::v-deep {
        width: 100%;
        justify-content: flex-start;
        .back {
          display: block;
        }
        .search-button {
          display: flex;
        }
        .search-control {
          display: none;
        }
      }
    }
    @media only screen and (max-width: $breakpoint-mobile) {
      box-shadow: none;
      .viewer-controls::v-deep {
        .pages-navigation-container .page-turn {
          display: none;
        }
      }
    }
    @media only screen and (max-width: 480px) {
      .viewer-controls::v-deep {
        justify-content: flex-start;
        .back {
          flex-grow: 0;
        }
        > * {
          &:not(:first-child) {
            margin-left: 0;
            margin-right: 8px;
          }
        }
      }
    }
  }
  .viewer {
    flex-grow: 1;
  }
}

.search-bar {
  &.search-control {
    width: 257px;
  }
  &.search-control::v-deep input {
    padding-top: 10px;
    height: 44px;
  }
  .search-icon {
    display: flex;
  }
}

.pages-navigation-container {
  display: flex;

  > *:not(:first-child) {
    margin-left: 8px;
  }

  .page-input {
    width: 140px;
  }

  button {
    &.page-turn {
      box-sizing: border-box;
      width: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
